
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import AreaSelect from '@/components/area-select.vue'
import OrderLogistics from '@/components/exchange-order/order-logistics.vue'
import {
    apiIntegralOrderDetail,
    apiIntegralGoodsConfirm,
    apiIntegralGoodsCancel
} from '@/api/application/integral_mall'
@Component({
    components: {
        LsDialog,
        AreaSelect,
        OrderLogistics
    }
})
export default class OrderDetail extends Vue {
    // S Data

    // 订单详情ID
    id: any = 0

    // 订单数据
    orderData: any = {
        address: {},
        user: {},
        admin_btns: {}
    }

    // E Data

    // S Methods
    // 获取订单详情
    getOrderDetail() {
        apiIntegralOrderDetail({ id: this.id }).then(res => {
            this.orderData = res
        })
    }

    // 取消订单
    orderCancel() {
        apiIntegralGoodsCancel({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // 确认收货
    orderConfirm() {
        apiIntegralGoodsConfirm({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // E Methods

    // 获取商品表格信息
    get getOrderGoods() {
        const { goods_snap, order_amount, total_num, order_integral } = this.orderData
        let goods: any[] = []
        if (goods_snap) {
            goods = [
                {
                    ...goods_snap,
                    order_amount,
                    total_num,
                    order_integral
                }
            ]
        }
        return goods
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getOrderDetail()
    }
}
